<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="addRequiredProjectItemsModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dodaj pozycje do zapotrzebowania projektowego</h5>
            <Link :href="route('articles.index')"></Link>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <label>Wyszukaj artykuł (nr. katalogowy, model lub opis)</label>
              <Vueform>
                <SelectElement name="articleId"
                               :search="true" :native="false" @select="selectArticle" :object="true"
                               :delay="1000" :filter-results="true" :items="getArticles" autocomplete="off"/>
              </Vueform>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>
            <form @submit.prevent="saveArticle">
              <button type="submit" class="btn btn-primary" v-if="selectedArticleId">
                <i class="bi bi-save me-2"></i>Dodaj do zapotrzebowania
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Modal} from "bootstrap";
import {ref} from "vue";

export default {
  props: {
    project: Number | String,
    team: Boolean | String
  },

  data() {
    return {
      modalObj: null,
      selectedArticleId: null
    }
  },

  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
  },

  methods: {

    selectArticle(data) {
      this.selectedArticleId = data.value;
    },

    async getArticles(data) {
      let customers = await axios.get(route('articles.search', {search: data}));
      return this.formatArticles(Object.values(customers.data));
    },

    formatArticles(articles) {
      return articles.map(item => ({
        label: (item.catalog_number || '') + ' - ' +
            (item.model || '') + ' ' +
            (item.description ? '- ' + item.description : '') +
            (item.producer_id ? ' - ' + item.producer_id : '') +
            ' - stan magazynowy: '+ (item.stock_quantity ? item.stock_quantity : 0),
        value: item.id
      }));
    },

    saveArticle() {
      this.$inertia.post(route("requiredProjectItems.addArticleToList", {
                project: this.project,
                team: this.team,
                article: this.selectedArticleId
              }
          ), {},
          {
            preserveState: true,
            onSuccess: () => {
              this.$emit("added-article");
              this.$emit("added-success", "Artykuł został poprawnie dodany do zapotrzebowania.");
              this.selectedArticleId = null;
            },
            onError: (resp) => {
              console.log("Błąd podczas zapisywania danych:", Object.keys(resp)[0] ?? resp);
              this.$emit("added-error", Object.keys(resp)[0] ?? resp);
            },
          }
      );
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
}

</script>

<style scoped>

</style>