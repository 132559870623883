<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Zamówienia</h4>
            </div>
            <div class="mt-3 row">

              <div class="col-md-12 col-lg-6">
                <label for="project-select">Wybierz projekt:</label>
                <select id="project-select" class="form-select form-select-sm" @change="updateSelectedProject"
                        v-model="selectedProjectId">
                  <option value="0" selected>Wszystkie projekty</option>
                  <option v-for="project in projects" :key="project.id" :value="project.id">
                    {{ project.label }}
                  </option>
                </select>
              </div>

              <div class="col-md-12 col-lg-6">
                <label for="team-select">Wybierz zespół:</label>
                <select id="team-select" class="form-select form-select-sm" @change="updateSelectedTeam"
                        v-model="selectedTeamName" :disabled="!selectedProjectId || (teams && teams.length === 0)">
                  <option value="" disabled>Wybierz zespół</option>
                  <option v-for="team in teams" :key="team.name" :value="team.name">
                    {{ team.name }}
                  </option>
                </select>
              </div>

            </div>
          </div>
        </div>

        <div class="widget-content">
          <div>
            <ejs-grid ref="orderListGrid" id="orderListGridId" height="500px"
                      :dataSource="orderListDataManager"
                      :allowTextWrap='true'
                      :allowExcelExport='false'
                      :allowPaging="true"
                      :allowSorting='true'
                      :allowFiltering='true'
                      :allowReordering='true'
                      :allowResizing='true'
                      :showColumnChooser='true'
                      :allowSelection='true'
                      :enablePersistence='false'
                      :allowGrouping='true'
                      :pageSettings="pageSettings"
                      :editSettings="editSettings"
                      :filterSettings="filterOptions"
                      :toolbar="toolbar"
                      :contextMenuItems="contextMenuItems"
                      :selectionSettings="selectionOptions"
                      :groupSettings='groupOptions'
                      :toolbarClick="clickToolbarHandler"
                      :contextMenuClick="clickContextMenuHandler"
                      :actionComplete="actionCompleteHandler"
                      :dataBound='dataBound'
                      @contextMenuOpen="onContextMenuOpen"
                      :keyPressed="onKeyDown">
              <e-columns>
                <e-column type="checkbox" width="70" :headerTemplate="'checkboxTemplate'"></e-column>
                <e-column field="id" headerText="ID" :allowEditing="false" isPrimaryKey="true"
                          :visible="false" :filter="filterDefault"></e-column>
                <e-column field="order_list.order_number" headerText="Numer zamówienia" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="quote_request_item_id" headerText="Zapytanie ofertowe" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="project.number" headerText="Projekt" :allowEditing="false" :visible="true"></e-column>
                <e-column field="team_name" headerText="Zespół" :allowEditing="false" :visible="true"></e-column>
                <e-column field="article.id" headerText="ID artykułu" :allowEditing="false" :visible="false"></e-column>
                <e-column field="article.catalog_number" headerText="Nr artykułu" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="article.model" headerText="Model artykułu" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="supplier.short_name" headerText="Wybrany dostawca" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="send_to_mails" headerText="Maile" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="send_to_mails_dw" headerText="Maile DW" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="sent_date" headerText="Data wysłania maili" :allowEditing="false"
                          :filter="filterDefault" type="date" editType="datepickeredit" format="dd.MM.yyyy"></e-column>
                <e-column field="delivery_date" headerText="Termin dostawy" :allowEditing="true"
                          :filter="filterDefault" format="dd.MM.yyyy" type="date" editType="datepickeredit"></e-column>
                <e-column field="quantity_required" headerText="Wymagana ilość" :allowEditing="true"
                          :filter="filterDefault"></e-column>
                <e-column field="quantity_received" headerText="Dostarczona ilość" :allowEditing="true"
                          :filter="filterDefault" editType="numericedit" format="N2"></e-column>
                <e-column field="price_netto" headerText="Cena netto" :allowEditing="true"
                          :filter="filterDefault" editType="numericedit" format="N2"></e-column>
                <e-column field="vat" headerText="VAT" :allowEditing="true"
                          :filter="filterDefault" editType="numericedit" format="N0"></e-column>
                <e-column field="currency" headerText="Waluta" :allowEditing="true" :edit="currenciesParams"
                          :filter="filterDefault" editType="dropdownedit"></e-column>
                <e-column field="status.name" headerText="Status" :allowEditing="true" editType="dropdownedit"
                          :edit="orderItemStatusesParams"></e-column>
                <e-column field="warehouse.short_name" headerText="Magazyn" :allowEditing="true" editType="dropdownedit"
                          :edit="orderItemWarehousesParams" :filter="filterWarehouses"></e-column>
                <e-column field="created_at" headerText="Data utworzenia" :allowEditing="false" format="dd.MM.yyyy"
                          :filter="filterDefault"></e-column>
                <e-column field="created_by_name" headerText="Utworzone przez" :allowEditing="false"
                          :filter="filterDefault"></e-column>

                <template v-slot:checkboxTemplate></template>
                <template v-slot:deliverersShortNamesTemplate="{data}">
                  {{ formatDeliverers(data) }}
                </template>
              </e-columns>

            </ejs-grid>
          </div>

        </div>
      </div>
    </div>

    <MoveItemToWarehouseModal ref="moveItemToWarehouseModal" :project="selectedProjectId" :team="selectedTeamName"
                              :selectedItem="selectedRecordToMove" :itemMovement="itemMovement"
                              @update-grid="updateGrid"
                              @added-success="successMsg" @added-error="errorMsg"
                              @added-article="this.$refs.orderListGrid.refresh()"/>

  </AppLayout>
</template>

<script>
import AppLayout from "../../Layouts/App.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {AutoComplete, MultiSelect} from '@syncfusion/ej2-vue-dropdowns';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
  Group,
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DataManager, WebMethodAdaptor} from '@syncfusion/ej2-data';
import {ComboBox, DropDownList} from "@syncfusion/ej2-dropdowns";
import moment from "moment";
import {ref} from "vue";
import MoveItemToWarehouseModal from "./MoveItemToWarehouseModal.vue";


let dropInstanceFilterUnits, statusElem, statusObj;
export default {

  components: {
    MoveItemToWarehouseModal,
    AppLayout,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, ContextMenu, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, Group]
  },

  props: {
    projects: Object,
    order_list_statuses: Object,
    units: Object,
    categories: Object,
    producers: Object,
    locations: Object,
    warehouses: Object,
  },

  beforeMount() {
    // Odczytanie danych z sesji
    const storedProjectId = sessionStorage.getItem('selectedProjectId');
    const storedTeam = sessionStorage.getItem('selectedTeam');

    if (storedProjectId) {
      this.selectedProjectId = storedProjectId === 'null' ? 0 : storedProjectId;
      this.updateTeams();
    }

    this.selectedTeamName = storedTeam;
    this.getRequiredProjectItemsForProject();
  },

  data() {

    return {
      teams: [],
      initialGrouping: true,
      orderListDataManager: [],
      fetchedOrders: [],
      selectedProjectId: 0,
      selectedTeamName: null,
      selectedRecordToMove: null,
      itemMovement: null,
      addRequiredProjectItemsModalRef: ref(null),
      editArticleModalRef: ref(null),
      showEditArticleModal: false,
      selectedArticleToEdit: null,
      selectedRecordsToSendOrder: null,
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu", operator: 'contains'},
      filterDefault: {operator: 'contains'},
      isRestoring: false,
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: {checkboxOnly: true},
      contextMenuItems: this.getContextMenu(),
      groupOptions: {showGroupedColumn: true, columns: ['order_list.order_number']},

      currenciesParams: {
        create: () => {
          return document.createElement('input');
        },
        read: () => {
          return this.comboBoxObj.value;
        },
        destroy: () => {
          this.comboBoxObj.destroy();
        },
        write: (args) => {
          this.comboBoxObj = new ComboBox({
            dataSource: [
              {text: 'ZŁ', value: 'ZŁ'},
              {text: 'EUR', value: 'EUR'},
              {text: 'USD', value: 'USD'},
            ],
            fields: {text: "text", value: "value"},
          });
          this.comboBoxObj.appendTo(args.element);
        }
      },

      filterUnits: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterCategories: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.categories,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kategorii',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterProducers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj producentów',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterSuppliers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.suppliers,
              fields: {text: 'short_name', value: 'short_name'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterWarehouses: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.warehouses,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      orderItemStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.order_list_statuses,
            fields: {value: 'id', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never',
            change: async (event) => {
              const cellElement = event.element.closest('.e-rowcell');
              const rowData = this.$refs.orderListGrid.getRowInfo(cellElement);
              statusObj.value = await this.onChangeStatus(event, rowData);
            }
          });
          statusObj.appendTo(statusElem);
        }
      },

      orderItemWarehousesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: (args) => {
          // Pobierz dane wiersza
          const cellElement = args.element.closest('.e-rowcell');
          const rowData = this.$refs.orderListGrid.getRowInfo(cellElement);

          // Ustal, jakie mają się pokazywać magazyny
          let filteredDataSource;
          if (rowData.rowData.quote_request_item_id === null) {
            console.log(this.warehouses);
            // Tylko magazyn główny- jeśli nie pochodzi z zapotrzebowania projektowego
            filteredDataSource = this.warehouses.filter((warehouse) => warehouse.id === 1);
          } else {
            // Wszystkie magazyny
            filteredDataSource = [{id: 0, name: "Brak", short_name: "Brak"}, ...this.warehouses];
          }

          statusObj = new DropDownList({
            dataSource: filteredDataSource,
            fields: {value: 'id', text: 'short_name'},
            enabled: true,
            placeholder: 'Wybierz magazyn',
            floatLabelType: 'Never',
            change: async (event) => {
              const cellElement = event.element.closest('.e-rowcell');
              const rowData = this.$refs.orderListGrid.getRowInfo(cellElement);
              statusObj.value = await this.onChangeWarehouse(event, rowData);
            }
          });
          statusObj.appendTo(statusElem);
        }
      },

      inventoryStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.inventory_statuses,
            fields: {value: 'name', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never'
          });
          statusObj.appendTo(statusElem);
        }
      },
    }
  },

  methods: {
    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    getToolbar() {
      return [
        "Add",
        "Update",
        "Cancel",
        {text: "Resetuj filtry", prefixIcon: 'e-reset', id: 'reset_filters'},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    clickToolbarHandler: async function (args) {
      /**
       * Niestandardowa akcja przy dodawaniu
       */
      if (args.item.text === 'Dodaj') {
        // args.cancel = true;

        // if (this.selectedProjectId && this.selectedTeamName)
        //   await this.$nextTick(() => {
        //     this.$refs.addRequiredProjectItemsModalRef.show();
        //   });
      }

      /**
       * Akcja przy usuwaniu artykułu.
       */
      if (args.item.text === 'Usuń') {
        const selectedRecords = this.$refs.orderListGrid.getSelectedRecords();
        if (selectedRecords.length > 0) {
          selectedRecords.forEach((record) => {
            this.$refs.orderListGrid.deleteRecord("id", record);
          });
        }
      }

      /**
       * Akcja przy kliknięciu resetowania filtrów
       */
      if (args.item.id === 'reset_filters') {
        this.$refs.orderListGrid.clearFiltering();
      }
    },

    /**
     * Akcja przy bindowaniu danych
     */
    dataBound: function () {
      // zamknij wszystkie pogrupowane pozycje
      if (this.initialGrouping === true) {
        this.$refs.orderListGrid.ej2Instances.groupModule.collapseAll();
        this.initialGrouping = false;
      }
    },

    getContextMenu() {
      return [
        {text: 'Duplikuj pozycje', target: '.e-content', id: 'duplicate_order_item'},
        {separator: true},
        {text: 'Wyślij zamówienie', target: '.e-content', id: 'send_order', enabled: false},
      ];
    },

    onContextMenuOpen(args) {
      // Pobierz dane wiersza, w którym otwarto menu
      const rowData = this.$refs.orderListGrid.getRowInfo(args.event.target)?.rowData;
      const contextMenuInstance = args.element.ej2_instances[0];

      // Włączy/wyłącz opcje wysłania zamówienia
      if (rowData?.status?.name === "Zatwierdzone") {
        contextMenuInstance.enableItems(["Wyślij zamówienie"], true); // Włącz opcję
      } else {
        contextMenuInstance.enableItems(["Wyślij zamówienie"], false); // Wyłącz opcję
      }
    },

    clickContextMenuHandler(args) {
      // Wyślij zamówienie
      if (args.item.id === 'send_order') {
        this.selectedRecordsToSendOrder = this.$refs.orderListGrid.getSelectedRecords();
        this.$nextTick(() => {
          console.log(this.selectedRecordsToSendOrder);
          this.successMsg('TUTAJ BEDZIE WYSYŁANIE MAILI');
        });
      }

      // Duplikuj pozycję
      if (args.item.id === 'duplicate_order_item') {
        Toast.fire({
          toast: false,
          position: 'center',
          icon: 'question',
          title: 'Klonowanie wierszy',
          html: 'Czy na pewno chcesz zduplikować wybrane wiersze?',
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: false,
          showConfirmButton: true,
          confirmButtonText: 'Tak',
          confirmButtonColor: 'success',
          showCancelButton: true,
          cancelButtonText: 'Nie',
        }).then((result) => {
          if (result.isConfirmed) {

            let rowsToDuplicate = this.$refs.orderListGrid.getSelectedRecords();
            this.$nextTick(() => {
              axios.post(route('orderLists.item.duplicate', {
                    orderListsItems: rowsToDuplicate
                  })
              ).then(response => {
                if (response.status === 200) {
                  this.successMsg('Wiersze skopiowane');
                  this.$refs.orderListGrid.refresh();

                } else {
                  this.errorMsg('Coś poszło nie tak: ', response.data.message);
                }
              }).catch(error => {
                console.log(error);
              });

            })
          }
        })
      }
    },

    updateSelectedProject(event) {
      this.selectedProjectId = Number(event.target.value);
      this.teams = null;
      this.selectedTeamName = null;
      if (this.selectedProjectId) {
        this.updateTeams()
      }

      this.getRequiredProjectItemsForProject();
    },

    updateTeams() {
      if (this.selectedProjectId) {
        const project = this.projects.find(project => project.id === Number(this.selectedProjectId));
        if (project) {
          this.teams = project.teams;
          this.selectedTeamName = null;
        }
      } else {
        this.teams = [];
        this.selectedTeamName = null;
      }
    },

    updateSelectedTeam(event) {
      this.selectedTeamName = event.target.value;
      this.getRequiredProjectItemsForProject();
    },

    getRequiredProjectItemsForProject() {
      sessionStorage.setItem('selectedProjectId', this.selectedProjectId);
      sessionStorage.setItem('selectedTeam', this.selectedTeamName);

      this.orderListDataManager = new DataManager({
        url: route('orderLists.getList', {project: this.selectedProjectId, team: this.selectedTeamName}),
        adaptor: new WebMethodAdaptor(),
        batchUrl: route('orderLists.saveChanges'),
        updateUrl: route('orderLists.updateChanges'),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token}
        ],
      });
    },

    dateFormatter(field, data) {
      const date = data.created_at ? data.created_at : null;
      if (date) {
        try {
          return moment(date).format('DD.MM.YYYY HH:mm:ss');
        } catch (error) {
          return data
        }
      }

      return '';
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        this.updateGrid();
      }
    },

    actionCompleteHandler(args) {
      if (args.requestType === "batchsave") {  // Tylko dla operacji batchSave (zapis)
        if (args.changes && args.changes.changedRecords) {
          // Sprawdzamy, czy `args.batchChanges` istnieje i czy są zmienione rekordy
          this.successMsg("Dane zostały pomyślnie zapisane!");

        } else if (args.error) {
          // Jeśli wystąpił błąd, wywołaj `errorMsg` z wiadomością błędu
          this.errorMsg("Wystąpił błąd podczas zapisu danych: " + args.error);
        }
      }
    },

    async onChangeStatus(event, rowData) {
      // Jeżeli user zmienił status na Zrealizowane
      if (!event.itemData || event.itemData?.name !== 'Zrealizowane') {
        return;
      }

      const result = await Toast.fire({
        toast: false,
        position: 'center',
        icon: 'question',
        title: 'Zakończenie zamówienia',
        html: 'Czy na pewno chcesz zmienić status na zrealizowany? To zamówienie zniknie z tej listy. Niezapisane zmiany nie zostaną zapisane.',
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        showConfirmButton: true,
        confirmButtonText: 'Tak',
        confirmButtonColor: 'success',
        showCancelButton: true,
        cancelButtonText: 'Nie',
      });

      if (result.isConfirmed) {
        let response = await axios.post(route('orderLists.order-completed', {orderListItem: rowData.rowData.id}))
        if (response.status === 200) {
          this.successMsg(response);
        }
        this.$refs.orderListGrid.refresh();
      }

    },

    async onChangeWarehouse(event, rowData) {
      // Czy powraca co poprzedniej wartości,
      // Jeśli tak to nie pokazuj Toasta
      if (this.isRestoring) {
        this.isRestoring = false;
        return;
      }

      const result = await Toast.fire({
        toast: false,
        position: 'center',
        icon: 'question',
        title: 'Zmiana magazynu',
        html: 'Czy na pewno chcesz zmienić magazyn dla tego artykułu?',
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        showConfirmButton: true,
        confirmButtonText: 'Tak',
        confirmButtonColor: 'success',
        showCancelButton: true,
        cancelButtonText: 'Nie',
      });

      if (result.isConfirmed) {

        // utworz ruch magazynowy
        this.selectedRecordToMove = rowData;
        this.itemMovement = event;

        await this.$nextTick(() => {
          this.$refs.moveItemToWarehouseModal.show();
        });

        return event.itemData;
      } else {
        this.isRestoring = true;
        return event.previousItemData;
      }
    },

    updateGrid() {
      // aktualizuj
      let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
      let buttons = toolbar.querySelectorAll('.e-toolbar-item');
      let updateButton = buttons[1].querySelector('button');

      if (updateButton) {
        updateButton.click();
      }
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    errorMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'warning',
        title: 'Błąd!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    formatDeliverers(data) {
      // Paruj JSON i formatuj jako ciąg znaków
      try {
        const names = JSON.parse(data.deliverers_short_names);
        return names.join(', ');
      } catch (e) {
        // Jeśli dane nie są poprawnym JSON-em, zwróć je bez zmian
        return data.deliverers_short_names;
      }
    }

  }
}
</script>

<style scoped>
.not-editable {
  color: orangered;
}
</style>