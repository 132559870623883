<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Lista lokalizacji</h4>
              <Link class="btn btn-outline-primary btn-sm" :href="route('articles.index')">Lista artykułów</Link>
            </div>
          </div>
        </div>

        <div class="widget-content">
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
              <tr>
                <th>Nazwa</th>
                <th>Krótka nazwa</th>
                <th>Opis</th>
                <th>Ilość artykułów w kategorii</th>
                <th>Akcje</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(warehouse, index) in warehouses" :key="warehouse.id">
                <td>
                  <input v-if="warehouse.isEditing" v-model="warehouse.edited_name" class="form-control"/>
                  <span v-else>{{ warehouse.name }}</span>
                </td>
                <td>
                  <input v-if="warehouse.isEditing" v-model="warehouse.edited_short_name" class="form-control"/>
                  <span v-else>{{ warehouse.short_name }}</span>
                </td>
                <td>
                  <input v-if="warehouse.isEditing" v-model="warehouse.edited_description" class="form-control"/>
                  <span v-else>{{ warehouse.description }}</span>
                </td>
                <td>{{ warehouse.articles_count }}</td>
                <td>
                  <button v-if="!warehouse.isEditing" @click="editWarehouse(warehouse)" type="button" class="btn btn-sm btn-warning">
                    Edytuj
                  </button>
                  <button v-if="warehouse.isEditing" @click="saveWarehouse(warehouse)" type="button" class="btn btn-sm btn-primary">
                    <i class="bi bi-save me-1"></i>Zapisz
                  </button>
                  <button v-if="warehouse.isEditing" @click="cancelEdit(warehouse, index)" type="button"
                          class="btn btn-sm btn-outline-primary ms-2">
                    <i class="bi bi-x-circle me-1"></i>Anuluj
                  </button>
                  <button v-if="!warehouse.isEditing && warehouse.articles_count < 1" @click="deleteWarehouse(warehouse)" type="button"
                          class="btn btn-sm btn-danger ms-2">Usuń
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <button v-if="!this.isCreatingNewWarehouse" class="btn btn-sm btn-outline-success" type="button" @click="createWarehouse">
              Dodaj nową
            </button>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "../../../Layouts/App.vue";
import {useForm} from "@inertiajs/vue3";

export default {
  components: {
    AppLayout,
  },

  props: {
    warehouses: Array,
  },

  data() {
    return {
      isCreatingNewWarehouse: false,
      form: useForm({
        name: null,
        short_name: null,
        description: null,
      }),
    };
  },

  methods: {
    // Włącza tryb edycji dla wybranego wiersza i zapisuje oryginalne wartości
    editWarehouse(warehouse) {
      this.warehouses.forEach((u) => {
        this.$set(u, "isEditing", false);
      });
      this.$set(warehouse, "isEditing", true);
      this.$set(warehouse, "edited_name", warehouse.name);
      this.$set(warehouse, "edited_short_name", warehouse.short_name);
      this.$set(warehouse, "edited_description", warehouse.description);
    },

    // Anuluje edycję i przywraca oryginalne wartości
    cancelEdit(warehouse, index) {
      if (!warehouse.id) {
        this.warehouses.splice(index, 1);
        this.isCreatingNewWarehouse = false;
      } else {
        warehouse.isEditing = false;
        warehouse.edited_name = warehouse.name;
        warehouse.edited_short_name = warehouse.short_name;
        warehouse.edited_description = warehouse.description;
      }
    },

    // Zapisuje zmiany do serwera
    saveWarehouse(warehouse) {
      this.form.name = warehouse.edited_name;
      this.form.short_name = warehouse.edited_short_name;
      this.form.description = warehouse.edited_description;

      if (!warehouse.id) {
        // Jeśli to nowy wiersz, użyj form.post
        this.form.post(route('locations.store'), {
          preserveScroll: true,
          onSuccess: () => {
            this.$set(warehouse, "isEditing", false);
          },
        });
      } else {
        // Jeśli to istniejący wiersz, użyj form.put
        this.form.put(route('locations.update', warehouse.id), {
          preserveScroll: true,
          onSuccess: () => {
            this.$set(warehouse, "isEditing", false);
          },
        });
      }
    },

    deleteWarehouse(warehouse) {
      this.form.delete(route('locations.destroy', warehouse.id), {
        preserveScroll: true,
      });
    },

    // Dodaje nowy wiersz do tabeli
    createWarehouse() {
      const newWarehouse = {
        id: null, // Nowy wiersz nie ma jeszcze ID
        name: "",
        short_name: "",
        description:"",
        articles_count: 0,
        isEditing: true,
        edited_name: "",
        edited_short_name: "",
      };
      this.warehouses.push(newWarehouse);
      this.isCreatingNewWarehouse = true;
    },
  },
};
</script>
