<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="addQuoteRequestModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ruch magazynowy</h5>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="form" class="container-fluid">

              <template v-if="form.oldWarehouse?.id === form.newWarehouse?.id">
                Brak ruchu magazynowego
              </template>

              <template v-else>

                <div v-if="form">
                  <table class="table order-list-table">
                    <tbody>
                    <tr>
                      <td class="widthFirstTd">Numer Dokumentu</td>
                      <td>
                        {{ form.document_number }}
                      </td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Magazyn po zmianie</td>
                      <td>
                        {{ form.newWarehouse.short_name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Ilość</td>
                      <td>
                        <div v-if="form.quantity === null || form.quantity === '' || form.quantity < 0"
                             class="text-danger small">
                          Ilość musi być większa lub równa 0
                        </div>
                        <input type="number" step="0.1" min="0" class="form-control form-control-sm"
                               v-model="form.quantity">
                      </td>
                    </tr>
                    <tr>
                      <td class="widthFirstTd">Komentarz</td>
                      <td>
                        <input type="text" class="form-control form-control-sm"
                               v-model="form.comment">
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

              </template>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>
            <form @submit.prevent="saveMovement" v-show="(form.oldWarehouse?.id !== form.newWarehouse?.id)">
              <button type="submit" class="btn btn-primary"
                      :disabled="form.processing || (form.quantity === null || form.quantity === '' || form.quantity < 0)">
                <i class="bi bi-save me-2"></i>Zapisz
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Modal, Tooltip} from "bootstrap";
import {useForm} from "@inertiajs/vue3";
import {DatePickerComponent} from "@syncfusion/ej2-vue-calendars";

export default {
  components: {'ejs-datepicker': DatePickerComponent},
  props: {
    project: Number | String,
    team: String,
    selectedItem: Object,
    itemMovement: Object
  },

  data() {
    return {
      errors: {},
      dueDateFormat: 'dd.MM.yyyy',
      form: useForm({})
    }
  },

  watch: {
    selectedItem: {
      immediate: true,
      async handler(newItem) {
        if (!newItem || !newItem.rowData)
          return false;


        // Wygenerowanie numeru dokumentu
        const documentNumber = await this.generateDocumentNumber(
            newItem.rowData.warehouse,
            this.itemMovement.itemData,
            newItem.rowData.id,
            newItem.rowData.quote_request_item_id || false
        );

        this.form = useForm({
          id: newItem.rowData.id,
          oldWarehouse: newItem.rowData.warehouse,
          newWarehouse: this.itemMovement.itemData,
          document_number: documentNumber,
          article_id: newItem.rowData.article_id,
          order_lists_item_id: newItem.rowData.order_lists_item_id,
          warehouse_id: this.itemMovement.itemData?.id,
          quantity: newItem.rowData.quantity,
          comment: '',
        });
      },
    },
  },


  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },

  methods: {

    formatDate(date) {
      if (!date)
        return '';

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },

    async generateDocumentNumber(oldWarehouse, newWarehouse, order_list_item_id, from_quote_request = false) {
      const response = await axios.get(
          route('warehouses.generate-document-number', {
            order_list_item_id: order_list_item_id,
            oldWarehouse: oldWarehouse,
            newWarehouse: newWarehouse,
            fromQuoteRequest: from_quote_request
          })
      );
      return response.data.number; // Zwracanie numeru dokumentu
    },

    async saveMovement() {
      this.form.processing = true;
      let response = await axios.post(route('warehouses.store', {'data': this.form}));

      if (response.status === 200)
        this.$emit('added-success', response.data);
      else {
        this.$emit('added-error', response.data);
      }

      this.$emit('update-grid', true);
      this.form.processing = false;

      this.hide();
    },

    hasError(field) {
      return this.errors && this.errors[field];
    },

    getErrorMessage(field) {
      if (!this.errors[field]) {
        return '';
      }

      if (/^Pole .*? jest wymagane\.$/.test(this.errors[field])) {
        return 'To pole jest wymagane.';
      }

      return 'To pole ma niepoprawną wartość';
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
}
</script>


<style scoped>
.widthFirstTd {
  width: 250px;
}

.order-list-table:not(:first-child) {
  border-top: 2px solid #222;
}
</style>