<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Zapytania ofertowe</h4>
            </div>
            <div class="mt-3 row">

              <div class="col-md-12 col-lg-6">
                <label for="project-select">Wybierz projekt:</label>
                <select id="project-select" class="form-select form-select-sm" @change="updateSelectedProject"
                        v-model="selectedProjectId">
                  <option value="0" selected>Wszystkie projekty</option>
                  <option v-for="project in projects" :key="project.id" :value="project.id">
                    {{ project.label }}
                  </option>
                </select>
              </div>

              <div class="col-md-12 col-lg-6">
                <label for="team-select">Wybierz zespół:</label>
                <select id="team-select" class="form-select form-select-sm" @change="updateSelectedTeam"
                        v-model="selectedTeamName" :disabled="!selectedProjectId || (teams && teams.length === 0)">
                  <option value="" disabled>Wybierz zespół</option>
                  <option v-for="team in teams" :key="team.name" :value="team.name">
                    {{ team.name }}
                  </option>
                </select>
              </div>

            </div>
          </div>
        </div>

        <div class="widget-content">
          <div>
            <ejs-grid ref="quoteListGrid" id="quoteListGridId" height="500px"
                      :dataSource="quoteRequestsDataManager"
                      :allowTextWrap='true'
                      :allowExcelExport='false'
                      :allowPaging="true"
                      :allowSorting='true'
                      :allowFiltering='true'
                      :allowReordering='true'
                      :allowResizing='true'
                      :showColumnChooser='true'
                      :allowSelection='true'
                      :enablePersistence='false'
                      :allowGrouping='true'
                      :pageSettings="pageSettings"
                      :editSettings="editSettings"
                      :filterSettings="filterOptions"
                      :toolbar="toolbar"
                      :contextMenuItems="contextMenuItems"
                      :selectionSettings="selectionOptions"
                      :groupSettings='groupOptions'
                      :toolbarClick="clickToolbarHandler"
                      :contextMenuClick="clickContextMenuHandler"
                      :actionComplete="actionCompleteHandler"
                      :dataBound='dataBound'
                      :keyPressed="onKeyDown">
              <e-columns>
                <e-column type="checkbox" width="70" :headerTemplate="'checkboxTemplate'"></e-column>
                <e-column field="id" headerText="ID" :allowEditing="false" isPrimaryKey="true"
                          :visible="false" :filter="filterDefault"></e-column>
                <e-column field="quote_request_number" headerText="Zapytanie ofertowe" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="article.id" headerText="ID artykułu" :allowEditing="false" :visible="false"></e-column>
                <e-column field="article.catalog_number" headerText="Nr artykułu" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="article.model" headerText="Model artykułu" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="created_at" headerText="Data wysłania" :allowEditing="false" format="dd.MM.yyyy"
                          :filter="filterDefault"></e-column>
                <e-column field="deliverers_short_names" headerText="Wysłano do" :allowEditing="false"
                          :filter="filterDefault" :template="'deliverersShortNamesTemplate'"></e-column>
                <e-column field="sent_to_emails" headerText="Wysłano na maile" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="supplier.short_name" headerText="Wybrany dostawca" :allowEditing="true"
                          :filter="filterDefault"></e-column>
                <e-column field="quote_request.due_date" headerText="Termin odpowiedzi" :allowEditing="false"
                          :filter="filterDefault" format="dd.MM.yyyy" type="date"></e-column>
                <e-column field="quote_request.project.number" headerText="Numer projekt"
                          :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="status.name" headerText="Status" :allowEditing="true"
                          :filter="filterDefault"></e-column>
                <e-column field="created_by_name" headerText="Utworzone przez" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="quantity_required" headerText="Wymagana ilość" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="quote_date" headerText="Data otrzymania wyceny" :allowEditing="true"
                          format="dd.MM.yyyy" type="date" editType="datepickeredit"></e-column>
                <e-column field="vat" headerText="VAT" :allowEditing="true"
                          :filter="filterDefault" editType="numericedit"></e-column>
                <e-column field="rabate" headerText="Rabat" :allowEditing="true" editType="numericedit" format="N2"
                          :filter="filterDefault"></e-column>
                <e-column field="quoted_price" headerText="Cena Netto" :allowEditing="true"
                          :filter="filterDefault" editType="numericedit" format="N2"></e-column>
                <e-column field="currency" headerText="Waluta" :allowEditing="true" editType="dropdownedit"
                          :edit='currenciesParams' :filter="filterDefault"></e-column>

                <template v-slot:checkboxTemplate></template>
                <template v-slot:deliverersShortNamesTemplate="{data}">
                  {{ formatDeliverers(data) }}
                </template>
              </e-columns>

            </ejs-grid>
          </div>

        </div>
      </div>
    </div>

    <AddOrderModal ref="addOrderModalRef" :project="selectedProjectId" :team="selectedTeamName"
                   :selectedItems="selectedRecordsToCreateOrder" @added-success="successMsg" @added-error="errorMsg"
                   @added-article="this.$refs.quoteListGrid.refresh()"/>


  </AppLayout>
</template>

<script>
import AppLayout from "../../Layouts/App.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {AutoComplete, MultiSelect} from '@syncfusion/ej2-vue-dropdowns';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
  Group,
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DataManager, WebMethodAdaptor} from '@syncfusion/ej2-data';
import {ComboBox, DropDownList} from "@syncfusion/ej2-dropdowns";
import moment from "moment";
import {ref} from "vue";
import AddOrderModal from "./AddOrderModal.vue";
import AddRequiredProjectItemsModal from "../RequiredProjectItems/AddRequiredProjectItemsModal.vue";


let dropInstanceFilterUnits, statusElem, statusObj;
export default {

  components: {
    AddRequiredProjectItemsModal,
    AddOrderModal,
    AppLayout,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, ContextMenu, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, Group]
  },

  props: {
    projects: Object,
    required_project_items_statuses: Object,
    inventory_statuses: Object,
    units: Object,
    categories: Object,
    producers: Object,
    warehouses: Object,
    exchangeRates: Object
  },

  beforeMount() {
    // Odczytanie danych z sesji
    const storedProjectId = sessionStorage.getItem('selectedProjectId');
    const storedTeam = sessionStorage.getItem('selectedTeam');

    if (storedProjectId) {
      this.selectedProjectId = storedProjectId === 'null' ? 0 : storedProjectId;
      this.updateTeams();
    }

    this.selectedTeamName = storedTeam;
    this.getRequiredProjectItemsForProject();
  },

  data() {

    return {
      teams: [],
      initialGrouping: true,
      quoteRequestsDataManager: [],
      fetchedOrders: [],
      selectedProjectId: null,
      selectedTeamName: null,
      addRequiredProjectItemsModalRef: ref(null),
      editArticleModalRef: ref(null),
      showEditArticleModal: false,
      selectedArticleToEdit: null,
      selectedRecordsToCreateOrder: null,
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu", operator: 'contains'},
      filterDefault: {operator: 'contains'},
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: {checkboxOnly: true},
      contextMenuItems: this.getContextMenu(),
      groupOptions: {showGroupedColumn: true, columns: ['quote_request_number']},

      currenciesParams: {
        create: () => {
          return document.createElement('input');
        },
        read: () => {
          return this.comboBoxObj.value;
        },
        destroy: () => {
          this.comboBoxObj.destroy();
        },
        write: (args) => {
          this.comboBoxObj = new ComboBox({
            dataSource: [
              {text: 'ZŁ', value: 'ZŁ'},
              {text: 'EUR', value: 'EUR'},
              {text: 'USD', value: 'USD'},
            ],
            fields: {text: "text", value: "value"},
          });
          this.comboBoxObj.appendTo(args.element);
        }
      },

      filterUnits: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterCategories: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.categories,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kategorii',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterProducers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj producentów',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterSuppliers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.suppliers,
              fields: {text: 'short_name', value: 'short_name'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterWarehouses: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.warehouses,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      requiredProjectItemsStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.required_project_items_statuses,
            fields: {value: 'name', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never'
          });
          statusObj.appendTo(statusElem);
        }
      },

      inventoryStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.inventory_statuses,
            fields: {value: 'name', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never'
          });
          statusObj.appendTo(statusElem);
        }
      },
    }
  },

  methods: {
    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    getToolbar() {
      return [
        "Add",
        {text: 'Usuń', prefixIcon: 'e-delete', id: 'delete_btn'},
        "Update",
        "Cancel",
        {text: "Resetuj filtry", prefixIcon: 'e-reset', id: 'reset_filters'},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    clickToolbarHandler: async function (args) {
      /**
       * Niestandardowa akcja przy dodawaniu
       */
      if (args.item.text === 'Dodaj') {
        args.cancel = true;

        if (this.selectedProjectId && this.selectedTeamName)
          await this.$nextTick(() => {
            this.$refs.addRequiredProjectItemsModalRef.show();
          });
      }

      /**
       * Akcja przy usuwaniu artykułu.
       */
      if (args.item.text === 'Usuń') {
        const selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        if (selectedRecords.length > 0) {
          selectedRecords.forEach((record) => {
            this.$refs.requiredProjectItemsGrid.deleteRecord("id", record);
          });
        }
      }

      /**
       * Akcja przy kliknięciu resetowania filtrów
       */
      if (args.item.id === 'reset_filters') {
        this.$refs.requiredProjectItemsGrid.clearFiltering();
      }
    },

    /**
     * Akcja przy bindowaniu danych
     */
    dataBound: function () {
      // zamknij wszystkie pogrupowane pozycje
      if (this.initialGrouping === true) {
        this.$refs.quoteListGrid.ej2Instances.groupModule.collapseAll();
        this.initialGrouping = false;
      }
    },

    getContextMenu() {
      return [
        {text: 'Utwórz zamówienie', target: '.e-content', id: 'create_order'},
      ];
    },

    clickContextMenuHandler(args) {
      if (args.item.id === 'create_order') {
        this.selectedRecordsToCreateOrder = this.$refs.quoteListGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.addOrderModalRef.show();
        });
      }
    },

    updateSelectedProject(event) {
      this.selectedProjectId = Number(event.target.value);
      this.teams = null;
      this.selectedTeamName = null;
      if (this.selectedProjectId) {
        this.updateTeams()
      }

      this.getRequiredProjectItemsForProject();
    },

    updateTeams() {
      if (this.selectedProjectId) {
        const project = this.projects.find(project => project.id === Number(this.selectedProjectId));
        if (project) {
          this.teams = project.teams;
          this.selectedTeamName = null;
        }
      } else {
        this.teams = [];
        this.selectedTeamName = null;
      }
    },

    updateSelectedTeam(event) {
      this.selectedTeamName = event.target.value;
      this.getRequiredProjectItemsForProject();
    },

    getRequiredProjectItemsForProject() {
      sessionStorage.setItem('selectedProjectId', this.selectedProjectId);
      sessionStorage.setItem('selectedTeam', this.selectedTeamName);

      this.quoteRequestsDataManager = new DataManager({
        url: route('quoteRequests.getList', {project: this.selectedProjectId, team: this.selectedTeamName}),
        adaptor: new WebMethodAdaptor(),
        batchUrl: route('requiredProjectItems.saveChanges'),
        updateUrl: route('requiredProjectItems.updateChanges'),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token}
        ],
      });
    },

    dateFormatter(field, data) {
      const date = data.created_at ? data.created_at : null;
      if (date) {
        try {
          return moment(date).format('DD.MM.YYYY HH:mm:ss');
        } catch (error) {
          return data
        }
      }

      return '';
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        // aktualizuj
        let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
        let buttons = toolbar.querySelectorAll('.e-toolbar-item');
        let updateButton = buttons[2].querySelector('button');
        if (updateButton) {
          updateButton.click();

        }
      }
    },

    actionCompleteHandler(args) {
      if (args.requestType === "batchsave") {  // Tylko dla operacji batchSave (zapis)
        if (args.changes && args.changes.changedRecords) {
          // Sprawdzamy czy `args.batchChanges` istnieje i czy są zmienione rekordy
          this.successMsg("Dane zostały pomyślnie zapisane!");

        } else if (args.error) {
          // Jeśli wystąpił błąd, wywołaj `errorMsg` z wiadomością błędu
          this.errorMsg("Wystąpił błąd podczas zapisu danych: " + args.error);
        }
      }
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    errorMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'warning',
        title: 'Błąd!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    formatDeliverers(data) {
      // Paruj JSON i formatuj jako ciąg znaków
      try {
        const names = JSON.parse(data.deliverers_short_names);
        return names.join(', ');
      } catch (e) {
        // Jeśli dane nie są poprawnym JSON-em, zwróć je bez zmian
        return data.deliverers_short_names;
      }
    }

  }
}
</script>

<style scoped>
.not-editable {
  color: orangered;
}
</style>